import jQuery from "jquery"
import {
    IDefaultIframeEvent,
    setIframeSize,
    addIframe,
    getIframes,
    calculateIframeId,
    calculateControllerIframeId,
    setIframeEndpoint,
} from "../services/iframeService"
import { EIframeType } from "../types/EIframeType"
import { ILookupOrderConfig } from "../types/ILookupOrderConfig"
import { IReturnedProductConfig } from "../types/IReturnedProductConfig"
import { reportVisibility } from "../services/visibilityService"

export interface IRecommendationPageOrchestratorConfigComponent {
    containerId: string
    types: EIframeType[]
}

export interface IStatusChange {
    status: "ready"
    type: "recommendations"
    data: any
}

export interface IRecommendationPageOrchestratorConfig {
    iframeEndpoint?: {
        endpoint?: string
        isSandbox?: boolean
    }
    order: ILookupOrderConfig
    components: IRecommendationPageOrchestratorConfigComponent[]
    returnedProducts?: IReturnedProductConfig[]
    onStatusChange?: (status: IStatusChange) => void
}

export interface SwiipeWindow extends Window {
    _Swiipe?: {
        componentsInitialized?: boolean
    }
}

declare let window: SwiipeWindow

export function initRecommendationPageOrchestrator(config: IRecommendationPageOrchestratorConfig) {
    if (window._Swiipe?.componentsInitialized) {
        console.log("Swiipe components orchestrator already initialized")
        return
    }
    if (config.components.filter((c) => c.types.includes("cart")).length > 1) {
        throw "Only one cart component allowed"
    }
    if (config.components.filter((c) => c.types.includes("modal")).length > 1) {
        throw "Only one modal component allowed"
    }

    window._Swiipe = window._Swiipe || {}
    window._Swiipe.componentsInitialized = true

    if (config.iframeEndpoint) {
        setIframeEndpoint(config.iframeEndpoint.endpoint, config.iframeEndpoint.isSandbox)
    }

    const modalComp = config.components.find((otherC) => otherC.types.includes("modal"))
    const cartComp = config.components.find((c) => c.types.includes("cart"))

    const controllerIframeId = calculateControllerIframeId(config.components)
    config.components.forEach((c) => jQuery("#" + c.containerId).empty())
    config.components.forEach((c) => {
        addIframe(
            {
                containerId: c.containerId,
                types: c.types,
                cartId: cartComp ? calculateIframeId(cartComp, config.components) : undefined,
                modalId: modalComp && modalComp !== c ? calculateIframeId(modalComp, config.components) : undefined,
            },
            calculateIframeId(c, config.components),
            controllerIframeId,
            config.order,
            config.returnedProducts ?? []
        )
    })

    const postToIframes = (obj: IDefaultIframeEvent, filterIds?: string[]) => {
        getIframes(filterIds).forEach((iframe) => {
            const iframeElem = iframe.getElem()[0] as HTMLIFrameElement
            iframeElem?.contentWindow?.postMessage(
                {
                    ...obj,
                    toId: obj.passToIframes?.includes(iframe.id) ? iframe.id : obj.toId,
                },
                "*"
            )
        })
    }

    const postMessageHandler = (event: MessageEvent<IDefaultIframeEvent>) => {
        if (!event.origin.split(":")[1].endsWith(".swiipe.com")) {
            return
        }

        if (event.data.fromId) {
            if (event.data.passToIframes) {
                postToIframes(event.data, event.data.passToIframes)
            }
            if (event.data.type === "scrollTo") {
                const position = event.data.data.position // top, center, bottom
                const $iframe = getIframes([event.data.fromId])[0]?.getElem()
                const y = ($iframe?.offset()?.top ?? 0) + event.data.data.scrollTop
                // Default to "top"
                const toScroll =
                    position === "bottom" ? y - window.innerHeight : position === "center" ? y - window.innerHeight / 2 : y
                window.scrollTo({
                    top: toScroll,
                    left: 0,
                    behavior: "smooth",
                })
            }
            if (event.data.type === "stateUpdate") {
                postToIframes({ fromId: event.data.fromId, type: "forceCheckForUpdates" })
            }
            if (event.data.type === "makeFullscreen") {
                setIframeSize(getIframes([event.data.fromId])[0]?.getElem(), "100%", "100%", event.data.data?.display)
            }
            if (event.data.type === "changeSize") {
                setIframeSize(
                    getIframes([event.data.fromId])[0]?.getElem(),
                    event.data.data.width,
                    event.data.data.height,
                    event.data.data?.display
                )
            }
            if (event.data.type === "redirectTo") {
                window.open(event.data.data.href, event.data.data.target || "_self")
            }
            if (event.data.type === "statusChange" && config.onStatusChange) {
                config.onStatusChange(event.data.data)
                if (event.data.data.status === "ready") {
                    handleVisibilityReporting(true)
                }
            }
        }
    }

    window.removeEventListener("message", postMessageHandler)
    window.addEventListener("message", postMessageHandler, false)

    const handleVisibilityReporting = (isOnLoad: boolean) => {
        reportVisibility((iframeId: string) => {
            postToIframes({ type: "isVisible", data: { value: true, onLoad: isOnLoad }, toId: iframeId }, [iframeId])
        })
    }

    let timeout: NodeJS.Timeout
    const scrollHandler = (event: Event) => {
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            handleVisibilityReporting(false)
        }, 500)
    }

    document.removeEventListener("scroll", scrollHandler)
    document.addEventListener("scroll", scrollHandler, false)
}
