import { getIframes } from "./iframeService"
import { isElementInViewport } from "../utils/domUtils"

const reportedVisibleIframeids: string[] = []

export function reportVisibility(handleVisible: (iframeId: string) => void) {
    getIframes()
        .filter(
            (iframe) =>
                (iframe.types.includes("recommendations") || iframe.types.includes("returnedproducts")) &&
                !reportedVisibleIframeids.includes(iframe.id)
        )
        .forEach((iframe) => {
            if (isElementInViewport(iframe.getElem()[0], 50)) {
                reportedVisibleIframeids.push(iframe.id)
                handleVisible(iframe.id)
            }
        })
}
